import { template as template_26cbf94b7bdc4f6882da76bc870aef63 } from "@ember/template-compiler";
const SidebarSectionMessage = template_26cbf94b7bdc4f6882da76bc870aef63(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
