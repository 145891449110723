import { template as template_e655907aa3f145a0a44da6872f07b9fa } from "@ember/template-compiler";
const FKLabel = template_e655907aa3f145a0a44da6872f07b9fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
